import { SubmitFunction } from "@remix-run/react";
import axios from "axios";
import { useCallback, useEffect, useRef } from "react";
import {
  UseDataFunctionReturn,
  useTypedFetcher as useFetcherRR,
  useTypedFetcher,
} from "remix-typedjson";

export function useTypedFetcherWithPromise<T>(opts?: Parameters<typeof useFetcherRR>[0]) {
  const resolveRef =
    useRef<(value: UseDataFunctionReturn<T> | PromiseLike<UseDataFunctionReturn<T>>) => void>();
  const promiseRef = useRef<Promise<UseDataFunctionReturn<T>>>();
  const fetcher = useTypedFetcher<T>(opts);

  const { load: loadFetcher, submit: submitFetcher, data, state } = fetcher;

  if (!promiseRef.current) {
    promiseRef.current = new Promise((resolve) => {
      resolveRef.current = resolve;
    });
  }

  const resetResolver = useCallback(() => {
    promiseRef.current = new Promise((resolve) => {
      resolveRef.current = resolve;
    });
  }, [promiseRef, resolveRef]);

  const submit: SubmitFunction = useCallback(
    async (...args) => {
      submitFetcher(...args);

      return promiseRef.current;
    },
    [submitFetcher, promiseRef],
  );

  const load = useCallback(
    async (path) => {
      loadFetcher(path);
      return promiseRef.current;
    },
    [loadFetcher, promiseRef],
  );

  const loadAxios = useCallback(async (path) => {
    const result = await axios.get(path);

    return result.data as UseDataFunctionReturn<T>;
  }, []);

  useEffect(() => {
    if (data && state === "idle" && resolveRef.current) {
      resolveRef.current(data);
      resetResolver();
    }
  }, [data, resetResolver, state]);

  return {
    ...fetcher,
    typedData: data as UseDataFunctionReturn<T>,
    submit,
    load,
    loadAxios,
  };
}
